import React from "react";
import { css } from "@emotion/core";
import Link from "./Link";

import chevronImg from "../../../static/img/icons/chevron.svg";
import { primaryColorDark } from "../../theme";

const breadcrumbStyles = css``;

const segStyles = css`
  position: relative;

  color: ${primaryColorDark};
  text-decoration: none;
  text-transform: capitalize;

  &:not(:last-child) {
    margin-right: 1.2em;

    &:after {
      content: "";
      display: inline-block;
      position: absolute;

      top: 50%;
      right: -1.2em;
      width: 1.2em;
      height: 1.2em;

      /* background-color: #fa0; */
      background-image: url(${chevronImg});
      background-position: center;
      background-size: 0.35em;

      background-repeat: no-repeat;

      transform: translate(0, -50%);
    }
  }
`;

const Breadcrumb = ({ paths }) => {
  return (
    <div css={breadcrumbStyles}>
      {paths.map((seg) => {
        return (
          seg && (
            <Link key={seg.path} to={"/" + seg.path} css={segStyles}>
              {seg.name}
            </Link>
          )
        );
      })}
    </div>
  );
};

export default Breadcrumb;
