import React, { useState } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/core";
import Img from "gatsby-image";
import Breadcrumb from "../components/library/Breadcrumb";
import TextInput from "../components/library/TextInput";
import TextArea from "../components/library/TextArea";
import ImageGrid from "../components/library/ImageGrid";
import { animated } from "react-spring";

import Form from "../components/library/Form";
import { useFade } from "../transitions";

const heroWrapperStyles = css`
  max-width: 70em;
  margin: auto;
`;

const pageWrapper = css`
  padding: 16px;
  max-width: 70em;
  margin: auto;
`;

const detailsWrapper = css`
  max-width: 600px;
  margin: auto;
`;

const puppyDetails = css`
  display: flex;
  flex-wrap: wrap;
`;

const puppyDetailItem = css`
  width: 50%;
  margin-bottom: 0;
`;

const bioStyles = css`
  margin-top: 24px;
`;

const formStyles = css``;

const Puppy = (data) => {
  const fade = useFade(data.transitionStatus);
  const { puppy } = data.data;
  const hero = puppy.frontmatter.coverImage.childImageSharp;

  const [personName, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [comments, setComments] = useState("");
  const breadcrumbs = data["uri"].slice(1).split("/").map((seg, i, arr) => {
    const path = arr.slice(0, i + 1).join("/");
    const name = i + 1 === arr.length ? puppy.frontmatter.name : seg;

    return {
      path,
      name,
    };
  });

  const images = puppy.frontmatter.pictures?.map(
    (pic) => pic.image.childImageSharp.fluid
  ) ?? [];

  return (
    <animated.div style={fade}>
      <div css={heroWrapperStyles}>
        {hero && (
          <Img fluid={puppy.frontmatter.coverImage.childImageSharp.fluid} />
        )}
      </div>
      <div css={pageWrapper}>
        <div className="mt4">
          <Breadcrumb paths={breadcrumbs} />
        </div>
        <div css={detailsWrapper}>
          <h1
            css={css`
              margin: 16px 0 0 0;
            `}
          >
            {puppy.frontmatter.name}
          </h1>
          <div css={puppyDetails}>
            {puppy.frontmatter.details &&
              puppy.frontmatter.details.map((dt, j) => {
                return (
                  <dl css={puppyDetailItem} key={j}>
                    <dt>{dt.name}</dt>
                    <dd>{dt.value}</dd>
                  </dl>
                );
              })}
          </div>
          <p css={bioStyles} dangerouslySetInnerHTML={{ __html: puppy.html }} />
          <div css={formStyles}>
            <h2 className="tac mb0">Interested?</h2>
            <h3 className="tac mb0 mt0">Let us know before they're gone</h3>
            <Form
              name="puppy-interest-form"
              completedEl={
                <>
                  <h2>Thanks for your enquiry</h2>
                  <h3>We'll get back to you as soon as we can</h3>
                </>
              }
            >
              {({ submitting, submitted }) => (
                <div css={{ paddingTop: 24 }}>
                  <input
                    type="hidden"
                    name="puppyName"
                    value={puppy.frontmatter.name}
                  />
                  <TextInput
                    name="personName"
                    label="Name*"
                    required
                    disabled={submitting || submitted}
                    onChange={(e) => setName(e.target.value)}
                    value={personName}
                  />
                  <TextInput
                    name="email"
                    label="Email"
                    disabled={submitting || submitted}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <div
                    css={{
                      display: "flex",
                    }}
                  >
                    <TextInput
                      name="personPhone"
                      label="Phone"
                      disabled={submitting || submitted}
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                    <TextInput
                      name="personCity"
                      label="City"
                      disabled={submitting || submitted}
                      onChange={(e) => setCity(e.target.value)}
                      value={city}
                    />
                  </div>
                  <TextArea
                    required
                    name="personComments"
                    label="Comments/Questions*"
                    disabled={submitting || submitted}
                    onChange={(e) => setComments(e.target.value)}
                    value={comments}
                  />

                  <button
                    disabled={submitting || submitted}
                    css={{
                      margin: "auto",
                    }}
                    className="primary"
                    type="submit"
                  >
                    Enquire
                  </button>
                </div>
              )}
            </Form>
          </div>
          {images.length > 0 && <div>
            <h2 className="light">Gallery</h2>
            <ImageGrid images={images} />
          </div>}
          <div>
            <h2 className="light">Details</h2>
            <p>{puppy.frontmatter.description}</p>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export const query = graphql`
  query PuppyQuery($id: String!) {
    puppy: markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        name
        coverImage {
          childImageSharp {
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
          id
          publicURL
        }
        description
        pictures {
          image {
            childImageSharp {
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          caption
        }
        details {
          name
          value
        }
      }
      html
      excerpt
    }
  }
`;

export default Puppy;
